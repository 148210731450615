import MoOAuth from './cmps/MoOAuth.js';
import MoDropDown from './cmps/MoDropDown.js';
import MoNotificationPanel from './cmps/MoNotificationPanel.js';
import logo_svg from '../images/logo.svg';


window.MoBar = class MoBar {
    constructor(params) {
        this.renderTo = null;
        this.preloadData = null;
        this.currentService = null;
        this.oauth2ClientId = null;
        this.authServerUrl = 'https://auth.motmom.com';
        this.isNotifyEnabled = true;
        this.notificationUrl = 'https://notify.motmom.com/poll';

        Object.assign(this, params);

        this.initialize();
    }

    initialize() {
        this.$el = this.renderTo || document.getElementById('mo_bar');

        const userLogin = this.preloadData && this.preloadData.user ? this.preloadData.user.login : '';

        this.logoTpl =
            '<li>' +
                '<a href="//motmom.com" class="header_logo">' +
                    '<img src="' + logo_svg + '">' +
                '</a>' +
            '</li>';

        this.anonymousNavTpl =
            `<li class="user_nav">
                <div class="sign_in_nav">
                    <a id="login_link">
                        <i class="icon_login"></i>
                        <span class="name">Войти</span>
                    </a>
                </div>
            </li>`;

        this.yaCounterTpl =
            //Yandex.Metrika global motmom counter
            '<noscript><div><img src="https://mc.yandex.ru/watch/38454160" style="position:absolute; left:-9999px;" alt="" /></div></noscript>';

        //init Yandex.Metrika global motmom counter
        (function (d, w, c) { (w[c] = w[c] || []).push(function() { try {
            w.yaCounter38454160 = new Ya.Metrika({
                id:38454160,
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true,
                trackHash:true,
                userParams:{login: userLogin}
            });
        } catch(e) { } });
        var n = d.getElementsByTagName("script")[0], s = d.createElement("script"), f = function () { n.parentNode.insertBefore(s, n); };
        s.type = "text/javascript"; s.async = true;
        s.src = "https://mc.yandex.ru/metrika/watch.js";
        if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); }
        else { f(); } })(document, window, "yandex_metrika_callbacks");

        this.oauth = new MoOAuth({
            clientId : this.oauth2ClientId,
            iFrameSrc : this.authServerUrl + '/oauth2/authorize'
        });

        if (this.preloadData && this.preloadData.external_script && this.preloadData.external_script.url) {
            const scriptNode = document.createElement('script');
            scriptNode.src = decodeURIComponent(this.preloadData.external_script.url);
            document.head.appendChild(scriptNode);
        }

        this.render(this.preloadData);

        this.setListeners();
    }

    setListeners() {
        let login_link = document.getElementById('login_link'),
            logout_link = document.getElementById('logout_link'),
            sign_in_url = this.authServerUrl + '/sign_in',
            sign_out_url = this.authServerUrl + '/sign_out';

        if (login_link) {
            login_link.onclick = function(){
                let href = login_link.getAttribute('href');

                if (!href || href.indexOf('?callback=') == -1)
                    login_link.setAttribute('href', sign_in_url + '?callback=' + window.location.href);
            }.bind(this);
        }

        if (logout_link){
            logout_link.onclick = function(){
                var href = logout_link.getAttribute('href');

                if(!href || href.indexOf('?callback=') == -1)
                    logout_link.setAttribute('href', sign_out_url + '?callback=' + window.location.href);
            }.bind(this);
        }
    }

    render(data) {
        var user_tpl = !data || data.is_anonymous ? this.anonymousNavTpl :
            this.getUserNavTpl(data.user_navigation, data.user.avatar_src, data.user.full_name),
            tpl;

        if (data && data.user && data.user.login) {
            const userLogin = data.user.login;

            if(window.yaCounter38454160)
                window.yaCounter38454160.userParams({login: userLogin});
        }

        const services = this.getSevicesTpl(data && data.services);

        const servicesList = services.join('');
        const servicesMenu = servicesList + services.map(() => '<a class="nav-icon-el--empty"></a>').join('');

        tpl =
            `<div class="mo_bar">
                ${this.yaCounterTpl}
                <ul class="service-nav">
                    ${this.logoTpl}
                    <li class="divider"></li>
                    <li class="services-dropdown">
                        <div class="services-list">
                            ${servicesList}
                            <div class="header"></div>
                        </div>
                        
                        <div class="services-menu sub_nav">
                            <div class="services-menu__container">
                                ${servicesMenu}
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="user-nav">
                    ${user_tpl}
                </ul>
            </div>`;

        if (data && data.confirm_email_message) {
            tpl +=
                `<div class="mo_bar_info_block">
                    В целях безопасности Вам необходимо ${data.confirm_email_message.text}
                    адрес электронной почты. <a href="${data.confirm_email_message.url}">
                    ${data.confirm_email_message.text}</a>
                </div>`;
        }

        this.$el.innerHTML = tpl;
        this.$user_menu_wrap = this.$el.getElementsByClassName('user-menu-wrap')[0];
        this.$services_dropdown = this.$el.getElementsByClassName('services-dropdown')[0];
        this.notification_panel = this.$el.getElementsByClassName('nf_panel')[0];

        new MoDropDown({applyTo: this.$services_dropdown});

        if (data && data.user)
            new MoDropDown({applyTo: this.$user_menu_wrap});

        if (this.isNotifyEnabled) {
            new MoNotificationPanel({
                applyTo : this.notification_panel,
                pollUrl : this.notificationUrl,
                oauth: this.oauth
            });
        }

        this.setListeners();
    }

    getSevicesTpl(services) {

        if (!services) {
            return service_items_tmp;
        }

        return services.map(item => {
            if (item.is_visible) {
                let item_cls = this.currentService === item.service_id ? ' active' : '';

                return `<a href="${item.href}" class="nav-icon-el btn_${item.service_id + item_cls}">
                    <i class="service-icon ${item.service_id}"></i>
                    <span class="name">${item.name}</span>
                </a>`;
            }
        });
    }

    getUserNavTpl(userMenuItems, avatar, displayName) {
        let items = '';
        userMenuItems.forEach(function(item) {
            items +=
                `<li class="user-menu__item">
                    <a href="${item.href}" class="user-menu__item-content btn_${item.service_id}">${item.name}</a>
                </li>`;
        });

        let user_tpl = `<li>
                <div class="user-menu-wrap nf_panel">
                    <a href="#" class="header" style="white-space: nowrap;">
                        <i class="mb-icon-arrow"></i>
                        <span class="avatar_wrapper">
                            <img src="${avatar}" width="42px" height="42px" class="avatar"/>
                        </span>
                        <span class="user-name">${displayName}</span>
                    </a>

                    <ul class="user-menu sub_nav">
                        <li class="user-menu__user-nav">
                            <a href="https://accounts.motmom.com" class="user-name">${displayName}</a>
                        </li>
                        ${items}
                        <li class="user-menu__item"><a id="logout_link" class="user-menu__item-content">Выйти</a></li>
                    </ul>
                </div>
            </li>`;

        return user_tpl;
    }

};
