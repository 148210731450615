export default class MoOAuth{
    constructor(params){
        this.lsKey = 'tokens';

        this.clientId  = null;
        this.iFrameSrc  = 'https://auth.motmom.com/oauth2/authorize';
        this.callbacksStack  = {};

        Object.assign(this, params);

        this.initialize();
    }

    initialize(){
        //Iframe Events Listeners
        if (window.addEventListener){
            window.addEventListener("message", this.eventHandler.bind(this), false);
        } else {
            window.attachEvent("onmessage", this.eventHandler.bind(this));
        }
    }

    renderAuthIFrame(src){
        var $iframe = document.createElement('iframe');

        $iframe.src = src;
        $iframe.width = 0;
        $iframe.height = 0;
        $iframe.setAttribute('style', 'visibility:hidden; position:absolute; top:0;');
        $iframe.name = "oauth_iframe";

        document.body.appendChild($iframe);
        return $iframe;
    }

    eventHandler(event){
        if (event.data.event_key != 'oauth_token') return;

        var callback_key = event.data.state;

        if (this.callbacksStack[callback_key]){
            this.callbacksStack[callback_key](event.data.token, event.data.expires_in);
            delete this.callbacksStack[callback_key];
        }
    }

    bildIFrameSrc(params){
        var defaults = {
                state : null,
                scope : 'common',
                client_id : this.clientId,
                response_type : 'token'
            },
            result = [];

        Object.assign(defaults, params);

        for(var param in defaults){
            result.push(param + '=' + defaults[param]);
        }

        return this.iFrameSrc + '?' + result.join('&');
    }

    getTokens(){
        return JSON.parse(localStorage.getItem(this.lsKey)) || {};
    }

    getToken(params, callback){
        var scope = params.scope,
            tokens = this.getTokens();

        //Token exist and not expired
        if (tokens && tokens[scope] && tokens[scope].exp_time > new Date().getTime()){
            return callback(tokens[scope].val, scope);
        } else {
            var callback_key = ['cb', scope, Math.floor(Math.random() * 1000)].join('_'),
                auth_iframe = null;

            this.callbacksStack[callback_key] = function(params){
                return function(token, expires_in){
                    this.setToken(params.scope, token, expires_in);

                    if (auth_iframe.remove) {
                        auth_iframe.remove();
                    } else {
                        auth_iframe.parentNode.removeChild(auth_iframe);
                    }

                    return callback(token, expires_in, params.state);
                }.bind(this)
            }.bind(this)(params);

            //sanding callback_key through state
            params.state = callback_key;

            auth_iframe = this.renderAuthIFrame(this.bildIFrameSrc(params));
        }
    }

    setToken(scope, token, expires_in){
        var tokens = this.getTokens();

        tokens[scope] = {
            val : token,
            exp_time : new Date().getTime() + parseInt(expires_in) * 1000 - 60 * 1000 * 5 //-5 min for confidence
        };

        localStorage.setItem(this.lsKey, JSON.stringify(tokens));
    }

    removeToken(scope){
        var tokens = this.getTokens();
        if (tokens[scope]) delete tokens[scope];

        localStorage.setItem(this.lsKey, JSON.stringify(tokens));
    }
};
