import { MoAjax } from '../base/MoAjax.js';

export default class MoNotificationPanel{
    constructor(params){
        this.applyTo = null;
        this.pollUrl = null;
        this.autoPoll = true;
        this.oauth = null;
        this.tokenScope = 'notification';
        this.errorSleepTime = 500;
        this.request = null;
        this.is_first_load = true;
        this.baseParams = {};
        this.initRequestParams = {
            initialize : true
        };

        let nfTypes = {
            booking: ['booking', 'service-reservations', 'phone-reservations'],
            tickets: [],
            coupons: []
        };

        Object.assign(this, params);

        if(!this.applyTo || !this.pollUrl || !this.oauth)
            return;

        this.el = this.applyTo;

        //notifications
        this.notifiers = {};
        Object.keys(nfTypes).forEach(function(name){
            this.notifiers[name] = new MoNotifier({
                types : nfTypes[name].length ? nfTypes[name] : [name],
                applyTo : this.el.getElementsByClassName('btn_' + name)[0]
            });
        }.bind(this));

        //Total notifications
        this.totalNfs = new MoNotifier({
            types: ['total'],
            applyTo : this.el.getElementsByClassName('avatar_wrapper')[0]
        });

        //Set up Poller
        if (this.autoPoll) this.poll();
    }

    poll(params) {
        let rp = Object.assign({}, this.baseParams, params);

        if (this.is_first_load && this.initRequestParams)
            Object.assign(rp, this.initRequestParams);

        if (!this.tokenScope){
            this.doRequest(rp);
        }else{
            this.oauth.getToken({
                scope : this.tokenScope
            }, function(token, state){
                var headers = {
                    "Authorization": "OAuth " + token
                };

                this.doRequest(rp, headers);
            }.bind(this));
        }
    }

    doRequest(rp, headers) {
        if(this.request) this.request.abort();

        this.request = MoAjax.cors_request({
            url: this.pollUrl,
            method: 'POST',
            headers: headers || {},
            data: rp,
            callback: this.onSuccess.bind(this),
            errback: this.onError.bind(this)
        });
    }

    onSuccess(resp){
        this.updateCounters(resp.data);

        this.is_first_load = false;
        this.errorSleepTime = 500;

        if (resp.ts) this.baseParams.ts = resp.ts;

        this.poll();
    }

    onError(){
        //send init request again
        this.is_first_load = true;
        this.errorSleepTime *= 2;

        window.setTimeout(this.poll.bind(this), this.errorSleepTime);
    }

    updateCounters(data){
        var total = 0;
        total += this.updateNfs(data.counters);
        this.totalNfs.setValue(total);
    }

    updateNfs(counters){
        var total = 0,
            value,
            notifier;

        Object.keys(this.notifiers).forEach(function(nf_key){
            value = 0;
            notifier = this.notifiers[nf_key];

            notifier.types.forEach(function(type){
                value += counters[type] || 0;
            }, this);
            notifier.setValue(value);
            total += value;
        }.bind(this));

        return total;
    }
};


/*
 * Mo.Notifier
 */
function MoNotifier(params){
    this.types = [];
    this.applyTo = null;
    this.value = 0;
    
    Object.assign(this, params);

    if(!this.applyTo || !this.types.length)
        return;

    this.el = this.applyTo;
};

MoNotifier.prototype.setValue = function(value){
    this.value = value;

    //value is 0
    if (!value){
        if (this.valueField) {
            this.valueField.remove();
            this.valueField = null;
        }
        return;
    }

    //value is not 0
    if(this.valueField){
        this.valueField.innerHTML = value;
    }else{
        this.valueField = document.createElement('span');
        this.valueField.className = 'badge';
        this.valueField.innerHTML = value;
        this.el.insertBefore(this.valueField, this.el.firstChild);
    }
};

MoNotifier.prototype.getValue = function(){
    return this.value;
};
