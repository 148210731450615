/*
 * MoAjax
 */
export let MoAjax = {
    cors_request: function(params) {
        var req = new XMLHttpRequest(),
            body = '';

        if('withCredentials' in req) {
            req.open(params.method, params.url, true);
            req.setRequestHeader('Accept', 'application/json, text/javascript');
            req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

            for(var header in params.headers){
                req.setRequestHeader(header, params.headers[header]);
            }

            req.onreadystatechange = function() {
                if (req.readyState === 4) {
                    if (req.status >= 200 && req.status < 400) {
                        params.callback(JSON.parse(req.responseText));
                    } else {
                        if(params.errback) params.errback();
                    }
                }
            };

            if(params.data){
                var body_arr = [];
                Object.keys(params.data).forEach(function(key){
                    body_arr.push(key + '=' + encodeURIComponent(params.data[key]));
                });
                body = body_arr.join('&');
            }

            req.send(body);
        }
    }
};

