export default class MoDropDown {
    constructor({applyTo}) {
        if (!applyTo)
            return;

        this.is_expand_subnav = false;

        this.$el = applyTo;
        this.$header = this.$el.getElementsByClassName('header')[0];

        this.elClassName = this.$el.className;

        this.setListeners();
    }

    static isChildOf($child, $parent) {
        let $cur_el = $child.parentNode;

        while ($cur_el != document.body) {
            if ($cur_el == $parent)
                return true;

            $cur_el = $cur_el.parentNode;
        }

        return false;
    }

    setListeners() {
        this.$header.onclick = function(event) {
            event.preventDefault();
            this.toggleSubnav();
        }.bind(this);

        //close subnav on outside click
        document.body.addEventListener('click', function(event) {
            if (!this.is_expand_subnav)
                return;

            if (!MoDropDown.isChildOf(event.target, this.$el))
                this.hideSubnav();
        }.bind(this));
    }

    toggleSubnav() {
        this.is_expand_subnav ? this.hideSubnav() : this.showSubnav();
    }

    showSubnav() {
        this.$el.className = this.elClassName + ' open';
        this.is_expand_subnav = true;
    }

    hideSubnav() {
        this.$el.className = this.elClassName;
        this.is_expand_subnav = false;
    }
};
